<template>
  <div class="container">
    <div class="row g-lg-5 pt-5">
      <div class="col">
        <h2 class="fw-bold lh-1 mb-1">Login to ListenLater</h2>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <i class="fa fa-spin fa-circle-notch"></i>&nbsp;
        Your session has expired. Trying to log you back in...
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import auth from '@/app/auth';

export default {
  name: 'AutoLogin',
  components: { },
  data() {
    return {
      tokenInvalid: false,
    };
  },
  methods: {
    async refreshAccessToken() {
      if (await auth.refreshAndSaveAccessToken()) {
        if (this.$route.query.redirect != null) {
          await this.$router.push(this.$route.query.redirect);
          return;
        }

        await this.$router.push({ name: 'AddNewArticle' });
      }
    },
  },
  async mounted() {
    await this.refreshAccessToken();
  },
};
</script>
