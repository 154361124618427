<template>
  <div class="container">
    <SiteNavHeader></SiteNavHeader>
    <div class="row">
      <div class="col p-0">
        <div v-if="processing">
          <i class="fa fa-spin fa-circle-notch fa-fw"></i>
          Please wait while we process your payment...
        </div>
        <div v-if="!processing">
          <div class="alert alert-success d-flex" v-if="saleSuccess">
            <div class="me-2">
              <i class="fa fa-check fa-fw"></i>
            </div>
            <div>
              Your purchase is successful. The credits should reflect in your account if you refresh the page.
              <div class="mt-2">
                <router-link class="btn btn-primary" to="/app/profile">Go to Profile</router-link>
              </div>
            </div>
          </div>
          <div class="alert alert-warning d-flex" v-if="salePending">
            <div class="me-2">
              <i class="fa fa-clock"></i>
            </div>
            <div>
              <div>
                It is taking slightly longer than usual to verify your recent purchase. <br />
                You should receive an email with a confirmation of your purchase shortly. <br />
                <br />

                If you do not receive the email or the credits in the next 2 minutes, please
                send an email to: support@listenlater.in with more details and
                we'll trouble shoot the issue for you.
              </div>

              <div class="mt-2">
                <button class="btn btn-sm btn-primary" @click="checkSaleStatus();">
                  <i class="fas fa-redo-alt"></i>
                  Check Again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteNavHeader from '@/components/SiteNavHeader';
import purchases from '@/app/purchases';

export default {

  name: 'PaymentDone',
  components: { SiteNavHeader },
  data() {
    return {
      processing: true,
      saleId: null,
      retryCount: 0,
      saleSuccess: false,
      salePending: false,
    };
  },
  methods: {
    async checkSaleStatus() {
      this.processing = true;
      this.salePending = false;
      this.saleSuccess = false;

      setTimeout(async () => {
        this.retryCount = 0;
        this.processing = true;

        const saleSuccess = await purchases.fetchSaleStatus(this.saleId);
        this.retryCount += 1;

        this.saleSuccess = saleSuccess;
        this.salePending = !saleSuccess;

        this.processing = false;
      }, 5000);
    },
  },
  async mounted() {
    this.saleId = this.$route.query.sale_id;
    await this.checkSaleStatus();
  },
};
</script>

<style scoped>

</style>
