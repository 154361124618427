<template>
  <div class="container">
    <div class="row g-lg-5 pt-5">
      <div class="col">
        <h2 class="fw-bold lh-1 mb-1">Login to ListenLater</h2>
      </div>
    </div>
    <div class="row mt-3" v-if="!tokenInvalid">
      <div class="col">
        <i class="fa fa-spin fa-circle-notch"></i>&nbsp;
        We are logging you in. Just a moment...
      </div>
    </div>
    <div class="row mt-3" v-if="tokenInvalid">
      <div class="col-12 col-md-8">
        <div class="border bg-light p-2 rounded-3 text-muted">
          <div class="mb-2">
            <i class="fa fa-exclamation-triangle fa-fw"></i>
            Your login URL is no longer valid. Please request a new one from the Login screen.
          </div>
          <router-link to="/login" class="btn btn-primary btn-sm">
            <i class="fa fa-magic fa-fw"></i>
            Get a new Magic Link</router-link>
        </div>
      </div>
    </div>
  </div>
  <LoginStatusChecker :is-public-page="true"></LoginStatusChecker>
</template>

<script>
import auth from '@/app/auth';
import authTokens from '@/app/authTokens';
import { useRoute } from 'vue-router';
import LoginStatusChecker from '@/components/LoginStatusChecker';

export default {
  name: 'Magic',
  components: { LoginStatusChecker },
  data() {
    return {
      tokenInvalid: false,
    };
  },
  methods: {
    async getAccessToken() {
      const route = useRoute();
      const { token } = route.params;
      if (!token) {
        this.tokenInvalid = true;
        return;
      }

      const result = await auth.getAccessToken(token);
      if (!result) {
        this.tokenInvalid = true;
      } else {
        // Save access token to local storage and expires
        authTokens.saveTokens(result.email, result.accessToken, result.expires,
          result.refreshToken, result.refreshTokenExpires);

        // Redirect to dashboard
        await this.$router.push({ name: 'AddNewArticle' });
      }
    },
  },
  async mounted() {
    await this.getAccessToken();
  },
};
</script>
