// jquery
import 'jquery/dist/jquery';

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min';

// Font awesome
import './lib/fontawesome-pro/css/all.css';

// Vex dialog
import 'vex-js/dist/css/vex.css';
import 'vex-js/dist/css/vex-theme-os.css';

// Luxon
import 'luxon';

// Toastr
import 'toastr/build/toastr.min.css';

/*
 * This has caused me a LOT of grief. The ordering of these files is important - else things go
 * hay wire. We need the base libraries loaded first -- so jQuery, Bootstrap, findeas, etc.
 *
 * Then the app needs to be loaded - which loads our trbo.scss
 *
 * And then finally we need to load libraries which build on these.
 *
 * So Toastr messes around big time with bootstrap's toast -- which we don't need. So this needs
 * to be loaded at the end.
 */

import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import api from '@/services/api';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);
app.config.globalProperties.$http = api;

// TODO: Add interceptors to $http for access token and refresh token

app
  .use(store)
  .use(router)
  .use(VueGtag,
    {
      appName: 'Listen Later',
      pageTrackerScreenviewEnabled: true,
      config: {
        id: 'G-NKK4L0WX39',
      },
    }, router)
  .mount('#app');
