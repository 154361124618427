<template>
  <div class="container">
    <div class="row g-lg-5 py-5">
      <!-- Left Panel -->
      <div class="col-lg-7 text-center text-lg-start">
        <div>
          <h1 class="display-4 fw-bold lh-1 mb-3 d-inline-block">ListenLater.in</h1>
          <h5 class="d-inline-block ms-1 text-muted">Beta</h5>
        </div>

        <h2 class="col-lg-10 fs-4 mb-3">
          Listen to articles you don't have time to read in your own private podcast show.
        </h2>
        <div class="mb-3">
          <router-link to="/signup" class="btn btn-lg btn-primary">
            <i class="fa fa-headphones fa-fw"></i>
            Sign up and get started for free!</router-link>
        </div>

        <div class="bg-light p-3 mt-3 hero-text-list">
          <h5 class="lh-1 mb-3">Watch a quick demo (1:03)</h5>
          <iframe class="video" src="https://www.youtube-nocookie.com/embed/ia0HGPpg58A"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>

        <div class="d-sm-block d-md-none mt-3">
          <LoginBox></LoginBox>
          <AudioSamplesBox class="mt-3"></AudioSamplesBox>
        </div>

        <div class="bg-light p-3 mt-3 hero-text-list">
          <h3 class="fw-bold lh-1 mb-3">How does this work?</h3>
          <ol>
            <li>Once you register, a private podcast URL is created just for you.</li>
            <li>You will need a Podcast app which allows for private podcasts (like Apple Podcasts, Google Podcasts, Overcast, etc) to use this service.</li>
            <li>You can choose the gender (male / female) of the narrator along with the accent (American / British) to read your article.</li>
            <li>Hit a button and the podcast is delivered to your podcast app. After you exhaust your free credits, you can purchase a credit pack to continue adding articles to your podcast.</li>
          </ol>
        </div>

        <div class="bg-light p-3 mt-4 hero-text-list">
          <h3 class="fw-bold lh-1 mb-3">FAQs</h3>
          <div class="faq-block">
            <div class="question">
              What podcast apps work with your service?
            </div>
            <div class="answer">
              <p>Any podcast app which allows for private podcasts or manual feeds works with
                ListenLater.</p>
              <p>Apple Podcasts, Google Podcast, Overcast, Pocket Casts, Downcast, Podcast Addict,
                Castro are some of the apps which support this and should work well with
                ListenLater.</p>
              <p>As of today, apps like Spotify and Amazon Music do not support manual feeds - so
                these will not work with ListenLater.</p>
            </div>
          </div>
          <div class="faq-block">
            <div class="question">
              How long does it take for an article to show up in my podcast?
            </div>
            <div class="answer">
              <p>
                It depends on the length of the article but usually between 30 seconds and 2 minutes.
              </p>
            </div>
          </div>
          <div class="faq-block">
            <div class="question">
              I want to add articles which are not publicly available.
            </div>
            <div class="answer">
              <p>
                We have an option for you to copy-paste the content of articles which are not
                publicly available or are behind a paywall that our service cannot access easily.
              </p>
            </div>
          </div>
          <div class="faq-block">
            <div class="question">
              What are credits?
            </div>
            <div class="answer">
              <p>
                You purchase credits to add articles to your podcast. Credits are spent whenever an
                article is converted to speech and added to your podcast.
              </p>
              <p>
                1 credit roughly translates to 1 minute of audio.
              </p>
            </div>
          </div>
          <div class="faq-block">
            <div class="question">
              Do credits expire?
            </div>
            <div class="answer">
              <p>
                Yes. This depends on the credit pack you purchase. For example, the trial credit
                pack comes with a 1 month lifetime.
              </p>
              <p>If you purchase an additional pack, the lifetime of your existing credits is
                also extended by the lifetime of the new pack.</p>
            </div>
          </div>
          <div class="faq-block">
            <div class="question">
              Do you offer refunds?
            </div>
            <div class="answer">
              <p>
                Unfortunately, no. We have a free trial which you should use to
                determine if this service works for you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Panel -->
      <div class="col-md-10 d-none d-md-block mx-auto col-lg-5">
        <LoginBox></LoginBox>
        <AudioSamplesBox class="mt-3"></AudioSamplesBox>
      </div>
    </div>
  </div>

  <LoginStatusChecker :is-public-page="true"></LoginStatusChecker>
</template>

<style scoped lang="scss">
.video {
  width: 100%;
  min-height: 200px;
}

@media only screen and (min-width: 600px) {
  .video {
    min-height: 400px;
  }
}

</style>

<script>
import LoginBox from '@/components/LoginBox';
import AudioSamplesBox from '@/components/AudioSamplesBox';
import LoginStatusChecker from '@/components/LoginStatusChecker';

export default {
  name: 'Home',
  components: {
    LoginStatusChecker,
    AudioSamplesBox,
    LoginBox,

  },
  mounted() {

  },
};
</script>
