<template>
  <div class="container">
    <SiteNavHeader></SiteNavHeader>
    <!-- Name and Email -->
    <div class="row" v-if="user != null">
      <div class="col">
        <h3>{{user.firstName}} {{user.lastName}}</h3>
        <span>
          <i class="fa fa-envelope fa-fw"></i>
          {{user.email}}</span>
      </div>
    </div>

    <!-- Podcast Url -->
    <div class="row mt-4" v-if="user != null">
      <div class="col-md-6 col-12">
        <i class="fa fa-podcast fa-fw"></i>
        Your podcast URL is:
        <div class="podcast-url-box mt-1">
          <div class="input-group">
            <input type="text" class="form-control" disabled readonly v-model="user.podcastVanityUrl" />
            <button class="btn btn-outline-secondary" @click="copyPodcastUrlToClipboard()" type="button">
              <i class="fa fa-copy fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Credits -->
    <div class="row mt-4" v-if="user != null">
      <div class="col-12 col-md-6">
        <h4>
          <i class="fa fa-exclamation-circle fa-fw" v-if="user.credits === 0 || creditsExpired"></i>
          <i class="fa fa-check fa-fw" v-if="user.credits > 0 && !creditsExpired"></i>
          Credits</h4>

        <div v-if="user.credits === 0" class="alert alert-secondary">
          <div>You do not have any credits in your account.</div>
          <div class="mt-1">You need credits to convert articles into podcasts.</div>
          <div class="small">(We offer a trial pack for as little as $0.99)</div>
          <router-link class="btn btn-primary mt-2" to="/app/buy">Purchase a Credit Pack</router-link>
        </div>

        <div v-if="user.credits > 0 && creditsExpired" class="alert alert-danger">
          <div>
            Your {{user.credits}} credits (about {{timeFromCredits}} of audio conversion) have
            expired on {{creditsExpiredOn}}. Credit packs are valid for a certain period of time on ListenLater.
          </div>
          <div class="mt-3">
            To re-activate expired credits, purchase a new credit pack. Your existing credits will
            rollover and be added to the new pack. The new validity will the same as the validity of
            the new pack you purchase.
          </div>
          <router-link class="btn btn-primary mt-3" to="/app/buy">Purchase a Credit Pack</router-link>
        </div>

        <div v-if="user.credits > 0 && !creditsExpired" class="alert alert-success">
          <div>
            You have {{user.credits}} credits (about {{timeFromCredits}} of audio conversion) in your
            account.
          </div>
          <div class="mt-2">
            These are valid until {{creditsExpiredOn}} (for another {{creditsTimeLeft}}).
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import utils from '@/app/utils';
import SiteNavHeader from '@/components/SiteNavHeader';
import users from '@/app/users';

export default {
  name: 'Profile',
  components: { SiteNavHeader },
  computed: {
    user() {
      const user = this.$store.getters.getUser;
      if (user == null) {
        return null;
      }

      return user;
    },
    creditsExpired() {
      return utils.hasUserCreditsExpired(this.user);
    },
    timeFromCredits() {
      return utils.calculateTimeForCredits(this.user.credits);
    },
    creditsExpiredOn() {
      if (this.user == null) {
        return null;
      }

      return DateTime.fromMillis(this.user.creditsExpiry).toLocaleString(DateTime.DATE_HUGE);
    },
    creditsTimeLeft() {
      if (this.user == null) {
        return null;
      }

      return DateTime.fromMillis(this.user.creditsExpiry)
        .toRelative({ unit: ['days'] }).replace('in ', '');
    },
  },
  methods: {
    copyPodcastUrlToClipboard() {
      this.copyToClipboard(this.user.podcastVanityUrl);
      utils.showToastSuccess('Podcast URL copied', 'The podcast URL was copied to your clipboard.');
    },
    copyToClipboard(str) {
      // Create a <textarea> element
      const el = document.createElement('textarea');

      // Set its value to the string that you want copied
      el.value = str;

      // Make it readonly to be tamper-proof
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';

      // Move outside the screen to make it invisible
      el.style.left = '-9999px';

      // Append the <textarea> element to the HTML document
      document.body.appendChild(el);

      // Check if there is any content selected previously
      const selected = document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0) // Store selection if found
        : false; // Mark as false to know no selection existed before

      // Select the <textarea> content
      el.select();

      // Copy - only works as a result of a user action (e.g. click events)
      document.execCommand('copy');

      // Remove the <textarea> element
      document.body.removeChild(el);

      // If a selection existed before copying
      if (selected) {
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
      }
    },
  },
  async mounted() {
    const user = await users.fetchUser();
    this.$store.commit('updateUser', user);
  },
};
</script>
