<template>
<div class="row mt-3 border-bottom mb-4">
  <div class="col-md-4 col-sm-12 text-center text-md-start">
    <h1 class="display-6 fw-bold site-title d-inline-block">
      <router-link to="/app/new">ListenLater.in</router-link>
    </h1>
    <h5 class="d-inline-block ms-1 text-muted">Beta</h5>
  </div>
  <div class="col-md-8 col-sm-12 text-center text-md-end" v-if="isUserLoggedIn">
    <div class="top-nav pb-2">
      <router-link to="/app/new">Add New</router-link>
      &nbsp;|&nbsp;
      <router-link to="/app/list">Existing Podcasts</router-link>
      &nbsp;|&nbsp;
      <router-link to="/app/profile">Profile</router-link>
      &nbsp;|&nbsp;
      <router-link to="/app/buy">Buy Credits</router-link>
      &nbsp;|&nbsp;
      <span v-if="credits != null">
        {{credits}} credits
        <span v-if="creditsExpired">
          <router-link class="red" to="/app/profile">(expired)</router-link>
        </span>
        &nbsp;|&nbsp;
      </span>
      <router-link to="/app/logout">Logout</router-link>
    </div>
  </div>
</div>
</template>

<script>
import authTokens from '@/app/authTokens';
import users from '@/app/users';
import utils from '@/app/utils';

export default {
  computed: {
    credits() {
      const user = this.$store.getters.getUser;
      return user == null ? null : user.credits;
    },
    creditsExpired() {
      return utils.hasUserCreditsExpired(this.$store.getters.getUser);
    },
    isUserLoggedIn() {
      return authTokens.isAccessTokenValid();
    },
  },
  data() {
    return {
    };
  },
  async mounted() {
    const lastUserUpdatedAt = this.$store.getters.getUserLastUpdated;
    const now = Date.now();

    if (lastUserUpdatedAt == null || (now - lastUserUpdatedAt > 60000)) {
      // Fetch new credits and update
      const user = await users.fetchUser();
      this.$store.commit('updateUser', user);
    }
  },
};
</script>
