<template>
  <div class="container">
    <div class="row g-lg-5 pt-5">
      <div class="col">
        <h2 class="fw-bold lh-1 mb-1">Verify your Email</h2>
      </div>
    </div>
    <div class="row mt-3" v-if="!tokenInvalid && !verificationSuccessful">
      <div class="col">
        <div class="p-2 p-md-0 text-center d-md-inline d-block">
          <i class="fa fa-spin fa-circle-notch"></i>&nbsp;
        </div>
        <span class="d-block d-md-inline">
          Verifying your email and creating your very own private podcast show. Just a moment please...
        </span>
      </div>
    </div>
    <div class="row mt-3" v-if="verificationSuccessful">
      <div class="col">
        <div class="p-2 p-md-0 text-center d-md-inline d-block">
          <i class="fa fa-check"></i>&nbsp;
        </div>
        <span class="d-block d-md-inline">
          All set! Please check your email for further instructions on how to use ListenLater!
        </span>
        <div class="mt-2">
          <router-link class="btn btn-primary btn-sm" to="/login">
            <i class="fa fa-magic fa-fw"></i>
            Login to ListenLater
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mt-3" v-if="tokenInvalid">
      <div class="col-12 col-md-8">
        <div class="border bg-light p-2 rounded-3 text-muted">
          <div class="mb-2">
            <div class="p-2 p-md-0 text-center">
              <i class="fa fa-exclamation-triangle fa-fw"></i>
            </div>
            <span class="d-block d-md-inline">
              <p>There was an issue verifying your account. Please check your email and try again.</p>
              <p>
                If this continues and you are unable to resolve the issue, please email us at:
                <a href="mailto:support@listenlater.in">support@listenlater.in</a> and we'll sort you out.
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoginStatusChecker :is-public-page="true"></LoginStatusChecker>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import auth from '@/app/auth';
import { useRoute } from 'vue-router';
import LoginStatusChecker from '@/components/LoginStatusChecker';

export default {
  name: 'Activate',
  components: { LoginStatusChecker },
  data() {
    return {
      tokenInvalid: false,
      verificationSuccessful: false,
    };
  },
  methods: {
    async verifyAndActivateUser() {
      const route = useRoute();
      const { token } = route.params;
      if (!token) {
        this.tokenInvalid = true;
        return;
      }

      const result = await auth.verifyUserEmail(token);
      if (!result) {
        this.tokenInvalid = true;
      } else {
        this.verificationSuccessful = true;
      }
    },
  },
  async mounted() {
    await this.verifyAndActivateUser();
  },
};
</script>
